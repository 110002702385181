import styled from "styled-components";

export const Container = styled.div`
    display: flex;

    h3 {
        margin: 0;
        padding: 0;
        font-family: "itc";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
    }

    #search {
        background: #F3F3F3;
        border-radius: 10px;
        height: 90px;
        color: black;
        padding: 24px 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #list {
        margin-left: 32px;
        width: 60%;
        border: 4px solid #C83890;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;
    }

    input {
        font-size: 1em;
        border: 2px solid #3B3B3B;
        box-sizing: border-box;
        border-radius: 5px;
        background: none;
        color: #3B3B3B;
        padding: 0.5em 0.5em;
        margin-top: 20px;
        caret-color: #F3F3F3;
        margin-right: 12px;
      }
    
      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus, 
      input:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 30px black inset !important;
      }
    
      input:-webkit-autofill {
        -webkit-text-fill-color: #F3F3F3 !important;
        -webkit-text-font-family: "itc";
      }
    
      input::placeholder {
        font-size: .9em;
      }

      .top-acc {

        text-align: left;

        input {
          font-size: 1em;
          border: 2px solid #F3F3F3;
          box-sizing: border-box;
          border-radius: 5px;
          background: none;
          color: #F3F3F3;
          padding: 0.5em 0.5em;
          margin-top: 14px;
          caret-color: #F3F3F3;
          margin-right: 12px;
        }
      
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px black inset !important;
        }
      
        input:-webkit-autofill {
          -webkit-text-fill-color: #F3F3F3 !important;
          -webkit-text-font-family: "itc";
        }
      
        input::placeholder {
          font-size: .9em;
        }

        h2 {
          margin: 0;
          font-family: "itc";
          font-style: normal;
          font-weight: 500;
        }
        .acc {
          background: #279686; 
        }

        .dec {
          background: #DF2E2E;
        }

        .edit-form {
          margin-top: 8px;
          margin-bottom: 8px;
        }

        .form {
          display: flex;
          align-items: start;
        }
      }

      .content-acc {
        padding: 24px;
        
        h2 {
          margin: 0;
          font-family: "itc";
          font-style: normal;
          font-weight: 500;
        }

        #token {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #DF347C;
          margin-bottom: 24px;
        }

        #member {
          margin-bottom: 12px;
        }

        .line {
          border: 1px solid #C4C4C4;
        }

        .member {
          display: flex;
          margin-top: 12px;

          img {
            margin-right: 12px;
          }

          .italic {
            font-style: italic;
          }

          .wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }

          .profile {
            display: flex;
          }

          .acc {
            background: #279686; 
          }

          .dec {
            background: #DF2E2E;
            margin-left: 8px;
          }
          p {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
          }
        }
      }

      @media (max-width: 992px) {
        flex-direction: column;

        #search {
            width: 100%;
            padding: 24px 0px;
        }

        #list {
            margin-top: 32px;
            width: 100%;
            margin-left: 0;
        }
      }


      @media screen and (max-width: 576px) {
          h2 {
              font-size: 18px;
          }

          .wrapper {
            flex-direction: column;
          }

          .action-btn {
            display: flex;
            width: 100%;
            justify-content: space-evenly;
            margin-top: 8px;
          }

          .form {
            flex-direction: column;
          }
      }

    @media screen and (max-width: 325px) {
        .btn-edit {
            font-size: 95%;
            width: 131px;
        }
    }
`
