import React from "react";
import { Container } from "./style.js"
import Search from "./search.js"
import ListTeam from "./listteam.js"

const DashboardTeam = () => {

    return (
        <Container>
            <Search />
            <ListTeam />
        </Container>
    )
}

export default DashboardTeam;