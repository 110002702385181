import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DefaultDashboardPage from "../../components/DashboardPage/index-dashboard"
import DashboardTeam from "../../components/DashboardPage/DashboardTeam"

const TeamPage = () => {
  return (
    <Layout>
      <SEO title="Team" />
      <DefaultDashboardPage>
        <DashboardTeam />
      </DefaultDashboardPage>
    </Layout>
  )
}

export default TeamPage
