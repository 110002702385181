import React, { useState } from "react"
import { Formik, Field, Form } from "formik"
import Button from "../../Button"
import member from "../../../../assets/images/dashboard-assets/member.svg"
import editicon from "../../../../assets/images/dashboard-assets/editicon-white.svg"
import { useDispatch } from "react-redux"
import {
  changeTeamName,
  approveMembershipRequest,
  declineMembershipRequest,
} from "../../../redux/slices/event/thunks"
import { toastActions } from "../../../redux/toast"

const headerStyle = {
  background: "linear-gradient(90deg, #AA3CA9 0%, #E2347A 100%)",
  color: "#F3F3F3",
  borderRadius: "10px",
  padding: "24px",
  marginTop: "24px",
}

const AccordionTop = (props) => {
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <div className="top-acc">
      {!isEdit && (
        <>
          <div
            style={{ display: "flex", alignItems: "start", zIndex: "10" }}
            onClick={() => setIsEdit(true)}
          >
            <h2>{props.name}</h2>
            <img src={editicon} style={{ color: "white", marginLeft: "8px" }} />
          </div>
        </>
      )}
      {isEdit && (
        <Formik
          initialValues={{
            name: props.name,
          }}
          onSubmit={async ({ name }) => {
            try {
              setIsLoading(true)
              dispatch(changeTeamName(props.idEvent, props.id, name)).then(
                () => {
                  toastActions.showSuccessToast("Name saved!")
                  setIsEdit(false)
                  setIsLoading(false)
                }
              )
            } catch {
              toastActions.showErrorToast("Failed to update team name")
            }
          }}
        >
          <Form>
            <div className="form">
              <Field
                name="name"
                type="text"
                placeholder="Enter your team name"
              />
              <div className="edit-form">
                <Button type="submit" className="acc">
                  {isLoading ? "Saving" : "Submit"}
                </Button>
                <Button
                  type="submit"
                  className="dec"
                  onClick={() => setIsEdit(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        </Formik>
      )}
      <p>{props.event}</p>
    </div>
  )
}

const Member = (props) => {
  const dispatch = useDispatch()
  const { idRequest, idTeam, idEvent } = props
  const [isLoadingAcc, setIsLoadingAcc] = useState(false)
  const [isLoadingDec, setIsLoadingDec] = useState(false)

  const accept = () => {
    setIsLoadingAcc(true)
    dispatch(approveMembershipRequest(idEvent, idTeam, idRequest)).then(() => {
      setIsLoadingAcc(false)
    })
  }

  const reject = () => {
    setIsLoadingDec(true)
    dispatch(declineMembershipRequest(idEvent, idTeam, idRequest)).then(() => {
      setIsLoadingDec(false)
    })
  }

  return (
    <div className="member">
      <div className="wrapper">
        <div className="profile">
          <img src={member} alt="member" />
          <div>
            <p>{props.name}</p>
            {!props.request && (
              <p className="italic">{props.leader ? "Leader" : "Member"}</p>
            )}
          </div>
        </div>
        {props.request && (
          <div className="action-btn">
            <Button className="acc" onClick={accept}>
              {isLoadingAcc ? "Accepting" : "Accept"}
            </Button>
            <Button className="dec" onClick={reject}>
              {isLoadingDec ? "Declining" : "Decline"}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const AccordionContent = (props) => {
  return (
    <div className="content-acc">
      <div id="token">
        <h2>Team ID: {props.token}</h2>
        <Button className="secondary">Copy</Button>
      </div>
      <div id="member">
        <h2>Team Members</h2>
        <div className="line" />
        <div className="member-container">
          {props.member.map((person, index) => {
            return (
              <Member
                name={person.account.name}
                leader={person.is_leader}
                key={`${index}-key`}
              />
            )
          })}
        </div>
      </div>
      {props.memberRequest.length > 0 && (
        <div id="member-request">
          <h2>Membership Requests</h2>
          <div className="line" />
          <div className="member-container">
            {props.memberRequest.map((person, index) => {
              return (
                <Member
                  name={person.account.name}
                  leader={person.is_leader}
                  key={`${index}-key`}
                  request
                  idRequest={person.id}
                  idEvent={props.idEvent}
                  idTeam={props.idTeam}
                />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export const renderTeamCard = (data, event) => {
  // console.log(data)
  if (data && event) {
    return data.map((team) => {
      return {
        header: (
          <AccordionTop
            name={team.name}
            id={team.id}
            event={event[team.event].name}
            idEvent={event[team.event].id}
          />
        ),
        content: (
          <AccordionContent
            member={team.members}
            memberRequest={team.membershipRequest}
            token={team.token}
            idTeam={team.id}
            idEvent={event[team.event].id}
          />
        ),
        headerStyle,
      }
    })
  }
}

export const cleanTeamData = (state) => {
  if (state) {
    return Object.entries(state).map((team) => {
      return {
        id: team[0],
        name: team[1].name,
        members: team[1].members,
        membershipRequest: team[1].membership_requests,
        token: team[1].token,
        event: team[1].registerable_event,
      }
    })
  }
  return undefined
}
