import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import { createMembershipRequest } from "../../../redux/slices/event/thunks";
import Button from "../../Button";
import { useDispatch } from "react-redux";

const Search = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)

    return (
        <div id="search">
            <h3>Join Team</h3>
            <Formik
                initialValues={{
                    teamToken: "",
                    }}
                    onSubmit={async ( {teamToken}, {resetForm} ) => {
                        setIsLoading(true)
                        dispatch(createMembershipRequest(teamToken)).then(() => {
                            setIsLoading(false)
                            resetForm({})
                        }).catch(() => {
                            setIsLoading(false)
                            resetForm({})
                        })
                    }}
            >
                <Form>
                    <Field name="teamToken" type="text" placeholder="Enter team token" />
                    <Button
                        type="submit"
                        className="submit-btn"
                    >
                        {
                        isLoading ? 
                            "Searching"
                            :
                            "Submit"    
                        }
                    </Button>
                </Form>
            </Formik>
        </div>
    )
}

export default Search;