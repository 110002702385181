import React from "react";
import Accordion from "../../Accordion";
import { useSelector } from "react-redux";

import { cleanTeamData, renderTeamCard } from "./utils";



const ListTeam = () => {

    const { teams, events } = useSelector(state => ({
        teams: cleanTeamData(state.event.teams),
        events: state.event.events
    }))

    return (
        <div id="list">
            <h3>Your Teams</h3>
            { teams && teams.length === 0 ? 
                <p>You don&apos;t have any team yet</p> 
                :
                <Accordion data={renderTeamCard(teams, events)} />
            }
            
        </div>
    )
}

export default ListTeam;